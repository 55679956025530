.react-contextmenu {
  background: #EBEDEE;
  border: 1px solid #999;
  border-radius: 5px;
}

.react-contextmenu .react-contextmenu-item{
  padding: 5px 10px;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #999;
}

.react-contextmenu .react-contextmenu-item:hover{
  background: #f5f7f8;
}

.react-contextmenu .react-contextmenu-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.react-contextmenu .react-contextmenu-item:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 0px;
}
